export const isEmpty = (item = '') => {
    return item.trim() === '';
};

export const capitalizeWords = (name) =>
    name?.replace(/\s\s+/g, ' ')
        .split(' ')
        .map(
            (item) => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()
        )
        .join(' ') || "";

export const toLowerCase = (value) => value.toLowerCase();

export const replaceKeysWithValues = (inputString, replacements) => {
    if (!inputString) return "";
    let resultString = inputString;

    for (const key in replacements) {
        if (replacements.hasOwnProperty(key)) {
            const regex = new RegExp(key, 'g'); // 'g' flag for global matching
            resultString = resultString.replace(regex, replacements[key]);
        }
    }

    return resultString;
}
