const Toggle = ({ isOn, onToggle, label, showAsterisk, disabled, id, register, labelClassName = "mr-4 p3-medium text-basicBlack" }) => {
    const handleToggle = () => {
        if (!disabled)
            onToggle(!isOn);
    };

    const getBgColor = () => {
        if (isOn)
            return disabled ? 'bg-primary-40' : 'bg-primary-500';
        else
            return disabled ? 'bg-gray-200' : 'bg-gray-100';

    }

    const getButtonColor = () => {
        if (isOn)
            return disabled ? 'bg-gray-50' : 'bg-basicWhite';
        else
            return disabled ? 'bg-gray-300' : 'bg-gray-500';
    }

    const getBorderColor = () => {
        if (isOn)
            return disabled ? 'border-primary-40' : 'border-primary-500';
        else
            return disabled ? 'border-gray-300' : 'border-gray-500';
    }

    return (
        <div className="flex items-center">
            {label && <span className={labelClassName}>{label}{showAsterisk && <span className="text-semantic-error-base"> *</span>}</span>}
            <div className={`relative ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} transition-colors duration-300 ease-in-out border-1 w-[39px] h-6 rounded-[75px] ${getBgColor()} ${getBorderColor()}`} onClick={handleToggle}>
                <div className={`${isOn ? 'right-1' : 'left-1'} ${getButtonColor()} transition-all duration-300 ease-in-out w-4 h-4 rounded-full top-[3px] absolute`} />
            </div>
            {register && id && (
                <input
                    type="checkbox"
                    checked={isOn}
                    id={id}
                    {...register(id)} // Register the toggle with react-hook-form
                    style={{ display: 'none' }} // Hide the actual checkbox
                    readOnly // Prevent user from directly changing the input
                />
            )}
        </div>
    );
};

export default Toggle;
