import React, { useEffect, useRef, useState } from 'react';
/**
 * 
 * @param inputSize: 'small', 'large' and 'dynamic'(default: small size for mobile and large size for tablet/desktop)
 * 
 */
const OtpInput = ({ otpLength = 6, value = "", onChange, disabled = false, hasError = false, inputRef, inputSize = 'dynamic' }) => {
    const [otp, setOtp] = useState(Array(otpLength).fill(""));
    const inputRefs = useRef([]);
    const [isFocused, setIsFocused] = useState(false);
    const baseClasses = "rounded-lg text-center p4-semibold focus:ring-0 !outline-none";

    useEffect(() => {
        if (value) {
            const valueArray = value.split("").slice(0, otpLength);
            const filledArray = [...valueArray, ...Array(otpLength - valueArray.length).fill("")];
            setOtp(filledArray);
            inputRefs.current[valueArray.length < otpLength ? valueArray.length : otpLength - 1].focus();
        } else {
            setOtp(Array(otpLength).fill(""));
            inputRefs.current[0].focus();
        }
    }, [value, otpLength]);

    const handleChange = (e, index) => {
        const { value } = e.target;
        if (/[^0-9]/.test(value)) return;

        const newOtp = [...otp];
        const inputArray = value.slice(0, otpLength - index).split("");
        inputArray.forEach((char, i) => {
            if (i + index < otpLength) {
                newOtp[i + index] = char;
            }
        });
        setOtp(newOtp);

        if (onChange) {
            onChange(newOtp.join(""));
        }

        if (value !== "") {
            if (index < otpLength - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace") {
            e.preventDefault();
            const newOtp = [...otp];
            if (otp[index] === "") {
                if (index > 0) {
                    newOtp[index - 1] = "";
                    inputRefs.current[index - 1].focus();
                }
            }
            else {
                newOtp[index] = "";
            }
            setOtp(newOtp);
            if (onChange) {
                onChange(newOtp.join(""));

            }
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData("text");
        if (/[^0-9]/.test(pastedData)) return;
        const newOtp = pastedData.slice(0, otpLength).split("");
        const filledArray = [...newOtp, ...Array(otpLength - newOtp.length).fill("")];
        setOtp(filledArray);
        inputRefs.current[newOtp.length < otpLength ? newOtp.length : otpLength - 1].focus();

        if (onChange) {
            onChange(newOtp.join(""));
        }
    };

    const handleFocus = (index) => {
        setIsFocused(true);
        const otpVal = otp.join('');
        if (index === otpVal.length) return;
        inputRefs.current[otpVal.length === otpLength ? otpVal.length - 1 : otpVal.length].focus();
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const getBackgroundClass = () => {
        if (disabled) {
            return "bg-gray-50";
        }
        return "bg-basicWhite";
    }

    const getBorderClass = () => {
        if (disabled) {
            return "border-gray-300";
        } else if (hasError) {
            return "border-semantic-error-base focus:!border-semantic-error-base";
        } else if (isFocused) {
            return "border-primary-400 focus:!border-primary-400";
        } else {
            return "border-gray-400 focus:!border-gray-400";
        }
    }

    const getTextClass = () => {
        if (disabled) {
            return "text-gray-400";
        }
        return "text-gray-900";
    }

    const getBoxGap = () => {
        switch (inputSize?.toLowerCase()) {
            case 'small': return 'gap-x-1.5';
            case 'large': return 'gap-x-2';
            default: return 'gap-x-1.5 md:gap-x-2';
        }
    }

    const getWidthHeight = () => {
        switch (inputSize?.toLowerCase()) {
            case 'small': return 'w-10 h-12';
            case 'large': return 'w-11 h-14';
            default: return 'w-10 h-12 md:w-11 md:h-14';
        }
    }

    const getBorderWidth = () => {
        switch (inputSize?.toLowerCase()) {
            case 'small': return 'border-[1.2px]';
            case 'large': return 'border-[1.5px]';
            default: return 'border-[1.2px] md:border-[1.5px]';
        }
    }

    useEffect(() => {
        if (inputRefs.current?.[0] && inputRef) {
            inputRef.current = inputRefs.current[0];
        }
    }, [inputRefs, inputRef]);

    return (
        <div className="flex flex-col items-center">
            <div
                className={`flex justify-center ${getBoxGap()}`}
            >
                {otp.map((value, index) => (
                    <input
                        key={index}
                        type="text"
                        inputMode="numeric"
                        autocomplete="one-time-code"
                        value={value}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        onPaste={handlePaste}
                        onFocus={() => handleFocus(index)}
                        onBlur={handleBlur}
                        ref={(el) => (inputRefs.current[index] = el)}
                        className={`${baseClasses} ${getWidthHeight()} ${getBorderWidth()} ${getBackgroundClass()} ${getTextClass()} ${getBorderClass(index)}`}
                        disabled={disabled}
                        autoComplete="off"
                    />
                ))}
            </div>
        </div>
    );
};

export default OtpInput;