import { menuItems } from '@components/Investor/NewDashboard/helper';
import { getAuth } from '@services/identity.service';
import {
    getInvestorBankDetails,
    getInvestorRelationshipManagerDetails,
    getIsFTI,
} from '@services/investors.service';
import { getWallet } from '@services/ai-safe.service';
import { useEffect, useState } from 'react';
import {
    getItemFromLocalStorage,
    saveItemToLocalStorage,
} from '@helpers/miscelleanous';
import {
    filterHeaderMenu,
    getKycStatus,
    getVan,
    isDematPresent,
    isKycVerified,
    isNotIfa,
    isNriInvestor,
    isValidResponseEntity,
    isWalletActive,
    isWalletUpdated,
    showJiraafSafe,
    showReferral,
} from '@helpers/utils';

const useNavItems = () => {
    const [investorCache, setInvestorCache] = useState(null);
    const [investor, setInvestor] = useState(null);
    const [wallet, setWallet] = useState(null);
    const [menuLoading, setMenuLoading] = useState(true);
    const [menu, setMenu] = useState([]);
    let isGettingInvestorFromWallet = false;

    /**
     * calling on first time load and page reload
     * update as investor/wallet updates
     */
    useEffect(() => {
        updateMenuAndCache();
    }, [investor, wallet]);

    const updateMenuAndCache = () => {
        setMenuLoading(true);
        const auth = getAuth();
        const investorCache = getItemFromLocalStorage('investorCache');
        setMenu(filterHeaderMenu(auth, menuItems, investorCache));
        if (auth?.token) {
            setInvestorCache(investorCache);
        }
        setMenuLoading(false);
    };

    const getWalletDetails = async (investorId) => {
        const walletResponse = await getWallet(investorId);
        if (isValidResponseEntity(walletResponse)) {
            updateWalletCache(walletResponse?.entity);
            setWallet(walletResponse);
            /**
             * in case of kyc not auto verified, but later verified then
             * if wallet is active for the investor -> kyc verified then
             * update menus and investorCache
             */
            if (isWalletUpdated(investorCache, walletResponse)) {
                isGettingInvestorFromWallet = true;
                await getInvestorDetails(getAuth().id);
            }
        } else {
            updateWalletCache();
        }
        return walletResponse;
    };

    const updateWalletCache = (entity) => {
        let investorCache = getItemFromLocalStorage('investorCache');
        investorCache = {
            ...investorCache,
            isWalletActive: isWalletActive(entity),
            hasWalletBalance: entity?.amount > 0, // added for highlighting wallet in bottom nav bar
        };
        updateCache(investorCache);
    };

    const getInvestorDetails = async (investorId) => {
        const investorResponse = await getInvestorBankDetails(investorId);
        if (isValidResponseEntity(investorResponse)) {
            updateInvestorCache(investorResponse?.entity);
            setInvestor(investorResponse);
        } else {
            updateInvestorCache();
        }
        return investorResponse;
    };

    const updateInvestorCache = (entity) => {
        let investorCache = getItemFromLocalStorage('investorCache');
        investorCache = {
            ...investorCache,
            isKycVerified: isKycVerified(entity),
            showReferral: showReferral(getAuth()),
            showJiraafSafe: showJiraafSafe(entity, investorCache),
            kycStatus: getKycStatus(entity),
            isDematPresent: isDematPresent(entity),
            isNri: isNriInvestor(entity),
            van: getVan(entity),
        };
        updateCache(investorCache);
        if (!entity) {
            updateMenuAndCache();
        }
    };

    const updateNriCache = (isNri) => {
        let investorCache = getItemFromLocalStorage('investorCache');
        investorCache = {
            ...investorCache,
            isNri: isNri || false,
        };
        updateCache(investorCache);
    };

    const updateDematCache = (isDematPresent) => {
        let investorCache = getItemFromLocalStorage('investorCache');
        investorCache = {
            ...investorCache,
            isDematPresent: isDematPresent || false,
        };
        updateCache(investorCache);
    };
    const getRmDetails = async (investorId) => {
        const response = await getInvestorRelationshipManagerDetails(
            investorId
        );
        const entity = response?.entity?.crmRelationshipManagersContact;
        if (isValidResponseEntity(response) && entity) {
            updateRmCache(entity);
        }
    };

    const updateRmCache = (entity) => {
        let investorCache = getItemFromLocalStorage('investorCache');
        const rmDetails = {
            name: entity?.name,
            email: entity?.email,
            mobile: entity?.mobile,
            image: entity?.profileImageLink,
        };
        investorCache = {
            ...investorCache,
            rmDetails,
        };
        updateCache(investorCache);
    };

    const getFTiDetails = async (investorId) => {
        const response = await getIsFTI(investorId);
        if (isValidResponseEntity(response)) {
            updateFTICache(response?.entity);
        } else {
            updateFTICache();
        }
        return response;
    };

    const updateFTICache = (entity) => {
        let investorCache = getItemFromLocalStorage('investorCache');
        investorCache = {
            ...investorCache,
            isFTI: entity?.isFTI || false,
        };
        updateCache(investorCache);
    };

    const updateCache = (updatedCache) => {
        saveItemToLocalStorage('investorCache', updatedCache);
        setInvestorCache(updatedCache);
    };

    /**
     * update investorCache and hence menu items
     */
    const updateMenus = async () => {
        const auth = getAuth();
        if (auth?.token) {
            setMenuLoading(true);
            await getWalletDetails(auth?.id);
            if(!isGettingInvestorFromWallet){
                await getInvestorDetails(auth?.id);
            }
            await getFTiDetails(auth?.id);
            if (isNotIfa(auth)) {
                await getRmDetails(auth?.id);
            }
        }
        setMenuLoading(false);
    };

    return {
        menu: menuLoading ? [] : menu,
        investorCache,
        menuLoading: menuLoading,
        setMenus: updateMenus,
        getInvestorDetails,
        getWalletDetails,
        getFTiDetails,
        updateNriCache: updateNriCache,
        updateDematCache: updateDematCache,
    };
};

export default useNavItems;
