import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs) {
    return twMerge(clsx(inputs));
}

/**
 * utility method that
 * return is Valid Response and Entity
 */
export const isValidResponseEntity = (response) => {
    return (response?.status && response?.entity) || false;
};

